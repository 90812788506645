import styled from '@emotion/styled';

export const FieldSet = styled.div`
  border-bottom: 1px solid lightgrey;
  margin-bottom: 30px;

  &:last-of-type {
    border: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &::after {
    content: '';
    display: block;
    margin-top: 30px;
  }

  &:last-of-type:after {
    margin-top: 10px;
  }
`;

export const FieldTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 10px;
  color: #333;
`;

export const FieldDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 10px;
  color: grey;
`;
