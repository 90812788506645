/**
 * Created by lilit on 2019-03-08.
 */

import React, { FC, useCallback, useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import { UserModel } from '@nimles/models/lib/identity';
import {
  Row,
  Column,
  Card,
  CardActions,
  CardAction,
  Modal,
  ModalAction,
  ModalActions,
  ModalBody,
  Button,
} from '@nimles/react-web-components';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import { InputField } from '@nimles/react-web-forms';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, forgotPassword, signInUser } from '@nimles/react-redux';
import { RootState } from '../../redux/types';
import { createPassword } from '../../utils';
import { CardArea } from './FormCard';
import { FieldSet } from '../fields/FieldSet';
import { AddressModel, LocationModel } from '@nimles/models';
import { Loader } from '@googlemaps/js-api-loader';

const loader = new Loader({
  apiKey: 'AIzaSyD0JeObfwLX1ZeTp4bOyh0fLbPWM0YXVSw',
  version: 'weekly',
});

interface GeocodeResponse {
  location: LocationModel;
  address: AddressModel;
}

interface Props {
  onSubmitted?: (values: any) => void;
}

export const EmailForm: FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language } = useContext(I18nextContext);
  const [userExists, setUserExists] = useState(false);

  const handleSubmit = async (
    values,
    { setSubmitting, setErrors, setStatus }
  ) => {
    try {
      const { username } = values;
      if (userExists) {
        await dispatch(signInUser({ username, password: values.password }));
      } else {
        const password = createPassword();

        const userResponse: any = await dispatch(
          createUser({
            username,
            password,
          })
        );

        if (userResponse?.username) {
          await dispatch(signInUser({ username, password }));
        } else {
          setUserExists(true);
        }
      }
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      console.error(error);
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values = {
    username: '',
    password: '',
  };

  return (
    <Formik
      initialValues={values}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      render={({ values, handleSubmit, isSubmitting }) => {
        const [modalIsOpen, setModalOpen] = useState(false);

        const handleForgotPassword = useCallback(async (username) => {
          await dispatch(forgotPassword(username));
          setModalOpen(false);
        }, []);

        return (
          <>
            <form onSubmit={handleSubmit}>
              <Card>
                <CardArea>
                  {userExists ? (
                    <p>
                      It already exists an user with that email. Provide the
                      password for the user or ask for a new password if you
                      don't know it. If you want to use another email{' '}
                      <a onClick={() => setUserExists(false)}>click here</a>.
                    </p>
                  ) : null}
                  <FieldSet>
                    <Row>
                      <Column flex>
                        <InputField
                          required
                          name="username"
                          label={t('prop.email')}
                          placeholder={t('prop.email')}
                          disabled={userExists}
                        />
                      </Column>
                    </Row>
                    {userExists ? (
                      <Row>
                        <Column flex>
                          <InputField
                            required
                            type="password"
                            name="password"
                            label={t('prop.password')}
                            placeholder={t('prop.password')}
                          />
                        </Column>
                      </Row>
                    ) : null}
                  </FieldSet>
                </CardArea>
                <CardActions
                  justify={userExists ? 'space-between' : 'flex-end'}
                >
                  {userExists ? (
                    <CardAction
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setModalOpen(true);
                      }}
                    >
                      {t('text.forgotPassword')}?
                    </CardAction>
                  ) : null}
                  <CardAction
                    primary
                    raised
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t(isSubmitting ? 'action.saving' : 'action.next')}
                  </CardAction>
                </CardActions>
              </Card>
            </form>
            <Modal open={modalIsOpen} onClose={() => setModalOpen(false)}>
              <ModalBody>
                <InputField
                  name="username"
                  label={t('prop.email')}
                  placeholder={t('prop.email')}
                  required
                />
              </ModalBody>
              <ModalActions>
                <ModalAction
                  onClick={(e) => {
                    e.preventDefault();
                    handleForgotPassword(values.username);
                  }}
                  primary
                >
                  {t('action.submit')}
                </ModalAction>
              </ModalActions>
            </Modal>
          </>
        );
      }}
    />
  );
};
