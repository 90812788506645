import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import styled from '@emotion/styled';
import { OrderModel, PaymentOptionModel } from '@nimles/models';
import { useDispatch } from 'react-redux';
import {
  initializePayment,
  loadPayment,
  submitOrder,
  updateOrder,
  reserveOrder,
  loadOrder,
  completePayment,
} from '@nimles/react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  CardActions,
  CardAction,
  Card,
  CardBody,
  Button,
  Column,
  Row,
} from '@nimles/react-web-components';
import stripe_image from '../../../images/stripe-blurple.svg';
import { getPayment } from '@nimles/rest-api';

interface Props {
  order: OrderModel;
  paymentOption: PaymentOptionModel;
  isOpen: boolean;
  onNext: () => void;
}

export function StripeForm({ order, paymentOption, isOpen, onNext }: Props) {
  const dispatch = useDispatch();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const StripeLogo = styled.img`
    height: 40px;
  `;

  const StripeContainer = styled.div`
    max-width: 40vw;
  `;
  const { t } = useTranslation();

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    await dispatch(
      updateOrder({
        ...order,
        head: { ...order.head, paymentOptionId: paymentOption.id },
      })
    );

    await dispatch(reserveOrder(order.id));

    const reservedOrder: any = await dispatch(loadOrder(order.id));

    await dispatch(initializePayment(reservedOrder?.head?.paymentIds[0], {}));

    const payment: any = await dispatch(
      loadPayment(reservedOrder?.head?.paymentIds[0])
    );

    const payload = await stripe.confirmCardPayment(payment.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      await dispatch(completePayment(reservedOrder?.head?.paymentIds[0], {}));

      await dispatch(submitOrder(order.id));

      setError(null);
      setProcessing(false);
      setSucceeded(true);
      onNext();
    }
  };

  return isOpen ? (
    <>
      Betalning
      <form onSubmit={handleSubmit}>
        <Card>
          <Row>
            <Column>
              <StripeLogo src={stripe_image} />
            </Column>
          </Row>
          <CardBody>
            <CardElement onChange={handleChange} />

            {/* Show any error that happens when processing the payment */}
            {error && (
              <div className="card-error" role="alert">
                {error}
              </div>
            )}
            {/* Show a success message upon completion */}
            <p
              className={succeeded ? 'result-message' : 'result-message hidden'}
            >
              <a href={`https://dashboard.stripe.com/test/payments`}></a>
            </p>
          </CardBody>
        </Card>
        <div style={{ textAlign: 'center' }}>
          <Button
            secondary
            raised
            type="submit"
            style={{ padding: '12px 50px' }}
          >
            {t('action.buyNow')}
          </Button>
        </div>
      </form>
    </>
  ) : null;
}
