/**
 * Created by lilit on 2019-03-08.
 */

import React, { FC } from 'react';
import { Formik, Form } from 'formik';
import { UserModel } from '@nimles/models/lib/identity';
import {
  Row,
  Column,
  Card,
  CardActions,
  CardAction,
} from '@nimles/react-web-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { InputField } from '@nimles/react-web-forms';
import { ProductGroupWithProductProperties } from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import {
  createUser,
  signInUser,
  createQuoteRequest,
  createFile,
  submitQuoteRequest,
  updateOrder,
} from '@nimles/react-redux';
import {
  OrderModel,
  PaymentOptionModel,
  QuoteRequestModel,
} from '@nimles/models/lib/ecommerce';
import { navigate } from 'gatsby';
import { RootState } from '../../../redux/types';
import { createPassword } from '../../../utils';
import { FileModel } from '@nimles/models/lib/media';
import { CardArea } from '../FormCard';
import { FieldSet } from '../../fields/FieldSet';
import { AddressModel, LocationModel } from '@nimles/models';
import { toLocation } from '../../map/Map';
import { Loader } from '@googlemaps/js-api-loader';
import { StripeForm } from './StripeForm';
import { loadStripe } from '@stripe/stripe-js';
import { useElements } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { DeliveryForm } from './DeliveryForm';
import { useState } from 'react';

const loader = new Loader({
  apiKey: 'AIzaSyD0JeObfwLX1ZeTp4bOyh0fLbPWM0YXVSw',
  version: 'weekly',
});

interface GeocodeResponse {
  location: LocationModel;
  address: AddressModel;
}

interface Props {
  onSubmitted?: (values: any) => void;
  productGroups?: ProductGroupWithProductProperties[];
  categoryId?: string;
  productGroupId?: string;
  order: OrderModel;
  isOpen: boolean;
  onNext: () => void;
  onSelect: () => void;
}

const promise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

export const BuyerForm: FC<Props> = ({ order, isOpen, onNext, onSelect }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector<RootState, UserModel>(
    ({ currentUser }) => currentUser.user
  );

  const paymentOptions = useSelector<RootState, PaymentOptionModel[]>(
    ({ publicPaymentOptions }) => publicPaymentOptions.values
  );

  const handleSubmit = async (
    values,
    { setSubmitting, setErrors, setStatus }
  ) => {
    try {
      await dispatch(
        updateOrder({
          ...order,
          ...values,
          head: {
            ...order.head,
            ...values.head,
            buyer: { ...order.head.buyer, ...values.head.buyer },
          },
        })
      );
      onNext();
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      console.error(error);
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values: OrderModel = {
    head: {
      buyer: {
        email: order.head.buyer.email || '',
        firstName: order.head.buyer.firstName || '',
        lastName: order.head.buyer.lastName || '',
        billingAddress: {
          street: order.head.buyer.billingAddress?.street || '',
          postalCode: order.head.buyer.billingAddress?.postalCode || '',
          city: order.head.buyer.billingAddress?.city || '',
        },
      },
    },
  };

  return isOpen ? (
    <>
      <Formik
        initialValues={values}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        enableReinitialize
        render={({ handleSubmit, isSubmitting }) => {
          return (
            <>
              <Form
                onSubmit={handleSubmit}
                name="simpleForm"
                className="quote-request-form"
              >
                Dina uppgifter
                <Card>
                  <CardArea>
                    <FieldSet>
                      <Row>
                        <Column flex>
                          <InputField
                            required
                            name="head.buyer.email"
                            label={t('prop.email')}
                            placeholder={t('prop.email')}
                            disabled={true}
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column flex>
                          <InputField
                            required
                            name="head.buyer.firstName"
                            label={t('prop.firstName')}
                            placeholder={t('prop.firstName')}
                          />
                        </Column>
                        <Column flex>
                          <InputField
                            required
                            name="head.buyer.lastName"
                            label={t('prop.lastName')}
                            placeholder={t('prop.lastName')}
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column flex>
                          <InputField
                            required
                            name="head.buyer.billingAddress.street"
                            label={t('prop.address')}
                            placeholder={t('prop.address')}
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column flex>
                          <InputField
                            required
                            name="head.buyer.billingAddress.postalCode"
                            label={t('prop.postalCode')}
                            placeholder={t('prop.postalCode')}
                          />
                        </Column>
                        <Column flex>
                          <InputField
                            required
                            name="head.buyer.billingAddress.city"
                            label={t('prop.city')}
                            placeholder={t('prop.city')}
                          />
                        </Column>
                      </Row>
                    </FieldSet>
                  </CardArea>
                  <CardActions>
                    <CardAction
                      primary
                      type="submit"
                      raised
                      disabled={isSubmitting}
                    >
                      {t(isSubmitting ? 'action.next' : 'action.next')}
                    </CardAction>
                  </CardActions>
                </Card>
              </Form>
            </>
          );
        }}
      />
    </>
  ) : (
    <>
      Dina uppgifter
      <Card>
        <CardArea>
          <p>
            {values.head.buyer.firstName} {values.head.buyer.lastName}
          </p>
          <p>{values.head.buyer.billingAddress.street}</p>
          <p>
            {values.head.buyer.billingAddress.postalCode},
            {values.head.buyer.billingAddress.city}
          </p>
          <CardActions>
            <CardAction primary onClick={onSelect}>
              {t('update')}
            </CardAction>
          </CardActions>
        </CardArea>
      </Card>
    </>
  );
};
