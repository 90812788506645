/**
 * Created by lilit on 2019-03-08.
 */

import React, { FC } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { ProductGroupWithProductProperties } from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import {
  CartModel,
  OrderModel,
  PaymentOptionModel,
} from '@nimles/models/lib/ecommerce';
import { RootState } from '../../../redux/types';

import { Loader } from '@googlemaps/js-api-loader';
import { StripeForm } from './StripeForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { DeliveryForm } from './DeliveryForm';
import { BuyerForm } from './BuyerForm';
import { useState } from 'react';
import { inactivateCart } from '@nimles/react-redux';
import { useCart } from '../../../hooks/cartHook';

const loader = new Loader({
  apiKey: 'AIzaSyD0JeObfwLX1ZeTp4bOyh0fLbPWM0YXVSw',
  version: 'weekly',
});

interface Props {
  onSubmitted?: (values: any) => void;
  productGroups?: ProductGroupWithProductProperties[];
  categoryId?: string;
  productGroupId?: string;
  order: OrderModel;
  cart: CartModel;
}

const promise = loadStripe('pk_test_DtDombyUOkOOonLE77CuaX7K');

export const CheckoutForm: FC<Props> = ({ order, cart }) => {
  const dispatch = useDispatch();
  const { navigate } = useI18next();
  const paymentOptions = useSelector<RootState, PaymentOptionModel[]>(
    ({ publicPaymentOptions }) => publicPaymentOptions.values
  );
  const [selectedForm, setForm] = useState('buyer');
  const { newCart } = useCart();

  const handleSubmitOrder = async () => {
    if (cart) {
      await dispatch(inactivateCart(cart.id));
      await newCart();
    }
    navigate(`/checkout/confirmation?orderId=${order.id}`);
  };

  return (
    <>
      <BuyerForm
        order={order}
        isOpen={selectedForm === 'buyer'}
        onNext={() => setForm('delivery')}
        onSelect={() => setForm('buyer')}
      />
      {selectedForm !== 'buyer' ? (
        <DeliveryForm
          order={order}
          isOpen={selectedForm === 'delivery'}
          onNext={() => setForm('payment')}
          onSelect={() => setForm('delivery')}
        />
      ) : null}

      {paymentOptions.map((paymentOption) => {
        switch (paymentOption.paymentProcessor) {
          case 'Stripe':
            return (
              <Elements stripe={promise}>
                <StripeForm
                  order={order}
                  paymentOption={paymentOption}
                  isOpen={selectedForm === 'payment'}
                  onNext={handleSubmitOrder}
                />
              </Elements>
            );

          default:
            break;
        }
      })}
    </>
  );
};
