import styled from '@emotion/styled';
import {
  CartItemModel,
  OrderLineModel,
  OrderModel,
  ProductModel,
} from '@nimles/models';
import { loadPublicProduct } from '@nimles/react-redux';
import { Column, List, ListItem, Row } from '@nimles/react-web-components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrency } from '../../../hooks/currencyHook';
import { RootState } from '../../../redux/types';
import { getImageUrl } from '../../../utils';
import { Price } from '../../price/Price';

const Image = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`;

export const OrderLines = ({ order }: { order: OrderModel }) => {
  return (
    <List>
      {order.lines.map((line) => {
        return (
          <ListItem>
            <div>
              <Row align="center" margin="0 -15px 10px">
                <Column>
                  {line.imageIds?.length ? (
                    <Image src={getImageUrl(line.imageIds[0])} />
                  ) : null}
                </Column>
                <Column flex>
                  <Row justify="flex-start">
                    <Column>
                      <strong>{line?.name}</strong>
                    </Column>
                  </Row>
                  <Row>
                    <Column>{line.quantity}x</Column>
                    <Column flex>
                      <Price
                        value={line.unitNetPrice}
                        currency={order.head.currency}
                      />
                    </Column>
                    <Column>
                      <Price
                        value={line.unitNetPrice * line.quantity}
                        currency={order.head.currency}
                      />
                    </Column>
                  </Row>
                </Column>
              </Row>
            </div>
          </ListItem>
        );
      })}
      <ListItem>
        <Row>
          <Column flex>Excl Vat</Column>
          <Column>
            <Price
              value={order.head.totalGrossPrice}
              currency={order.head.currency}
            />
          </Column>
        </Row>
      </ListItem>
      <ListItem>
        <Row>
          <Column flex>Vat</Column>
          <Column>
            <Price value={order.head.totalVat} currency={order.head.currency} />
          </Column>
        </Row>
      </ListItem>
      <ListItem>
        <Row>
          <Column flex>Total incl Vat</Column>
          <Column>
            <Price
              value={order.head.totalNetPrice}
              currency={order.head.currency}
            />
          </Column>
        </Row>
      </ListItem>
    </List>
  );
};
