import styled from '@emotion/styled';
import { Card, CardBody } from '@nimles/react-web-components';

export const FormCard = styled(Card)``;

export const CardAreaTitle = styled.h2`
  font-size: 22px;
  font-weight: 700;
  margin: 10px 0 30px;
  color: #434345;
`;

export const CardArea = styled(CardBody)`
  border-bottom: 1px solid lightgray;
  box-shadow: 0 1px #efefef;
  padding-bottom: 20px;

  &:last-of-type {
    border: 0;
  }
`;
