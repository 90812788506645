import React, { useEffect, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CheckoutForm } from '../components/forms/checkoutForm/CheckoutForm';
import Layout from '../layouts/Layout';
import { Card, Column, Container, Row } from '@nimles/react-web-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';
import { EmailForm } from '../components/forms/EmailForm';
import { OrderLines } from '../components/order/lines/OrderLines';
import { CartModel, OrderModel, UserModel } from '@nimles/models';
import {
  loadCurrentUser,
  loadPublicDeliveryOptions,
  loadPublicPaymentOptions,
  State,
} from '@nimles/react-redux';
import { useOrder } from '../hooks/orderHook';
import { compareBy } from '../utils';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.
// Sign in to see examples pre-filled with your key.
const promise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

const Checkout = ({ location }) => {
  const dispatch = useDispatch();
  const { selected, values: orders, loaded } = useSelector<
    RootState,
    State<OrderModel>
  >(({ orders }) => orders);

  const { t } = useTranslation();

  const accessToken = useSelector<RootState, string>(
    ({ auth }) => auth.accessToken
  );

  const cart = useSelector<RootState, CartModel>(({ carts }) => carts.selected);

  const user = useSelector<RootState, UserModel>(
    ({ currentUser }) => currentUser.user
  );

  const { createOrUpdateOrder } = useOrder();

  useEffect(() => {
    if (accessToken && !user) {
      dispatch(loadCurrentUser());
    }
  }, [accessToken, user]);

  useEffect(() => {
    if (cart?.id && user?.id) {
      createOrUpdateOrder(cart.id);
    }
  }, [cart, user]);

  useEffect(() => {
    dispatch(loadPublicPaymentOptions());
    dispatch(loadPublicDeliveryOptions());
  }, []);

  const order = useMemo<OrderModel>(
    () =>
      cart &&
      orders
        ?.sort(compareBy('lastModified', null, true, 'date'))
        ?.find(({ head }) => head.cartId === cart.id),
    [cart, orders]
  );

  return (
    <Layout header location={location} isCheckout>
      {order && (
        <Container padding="200px 0 0">
          <Row justify="center" wrap="wrap">
            <Column xs={100} lg={50}>
              <CheckoutForm order={order} cart={cart} />
            </Column>
            <Column xs={100} lg={50}>
              {t('prop.shoppingCart')}
              <Card>
                <OrderLines order={order} />
              </Card>
            </Column>
          </Row>
        </Container>
      )}
      {!order && !accessToken && (
        <Container padding="200px 0 0">
          <Row justify="center">
            <Column xs={100}>
              <EmailForm />
            </Column>
          </Row>
        </Container>
      )}
      {!order && accessToken && (
        <Container padding="200px 0 0">
          <Row justify="center">
            <Column>Preparing order... </Column>
          </Row>
        </Container>
      )}
    </Layout>
  );
};

export default Checkout;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
